export const ulcerLocations = new Map([
    ["1", "Ankle- Left/Back"],
    ["2", "Ankle- Left/Front"],
    ["3", "Ankle- Right/Back"],
    ["4", "Ankle- Right/Front"],
    ["5", "Ant Thorax"],
    ["6", "Antecubital- Left"],
    ["7", "Antecubital- Right"],
    ["8", "Buttock- Left"],
    ["9", "Buttock- Right"],
    ["10", "Cheek- Left"],
    ["11", "Cheek- Right"],
    ["12", "Chin"],
    ["13", "Ear- Left/Back"],
    ["14", "Ear- Left/Front"],
    ["15", "Ear- Right/Back"],
    ["16", "Ear- Right/Front"],
    ["17", "Elbow- Left"],
    ["18", "Elbow- Right"],
    ["19", "Foot- Left/Bottom"],
    ["20", "Foot- Left/Top"],
    ["21", "Foot- Right/Bottom"],
    ["22", "Foot- Right/Top"],
    ["23", "Hand- Left/Back"],
    ["24", "Hand- Left/Palm"],
    ["25", "Hand- Right/Back"],
    ["26", "Hand- Right/Palm"],
    ["27", "Heel- Left"],
    ["28", "Heel- Right"],
    ["29", "Ischium- Left"],
    ["30", "Ischium- Right"],
    ["31", "Knee- Left/Back"],
    ["32", "Knee- Left/Front"],
    ["33", "Knee- Right/Back"],
    ["34", "Knee- Right/Front"],
    ["35", "Lower Arm- Left/Back"],
    ["36", "Lower Arm- Left/Front"],
    ["37", "Lower Arm- Right/Back"],
    ["38", "Lower Arm- Right/Front"],
    ["39", "Lower leg- Left/Front"],
    ["40", "Lower leg- Right/Front"],
    ["41", "Lower leg- Right/Back"],
    ["42", "Lower leg- Left/Back"],
    ["43", "Neck/Back"],
    ["44", "Neck/Front"],
    ["45", "Nose"],
    ["46", "Occiput"],
    ["47", "Post Thorax"],
    ["48", "Sacrum/Coccyx"],
    ["49", "Scalp"],
    ["50", "Scapula- Left"],
    ["51", "Scapula- Right"],
    ["52", "Shoulder- Left"],
    ["53", "Shoulder- Right"],
    ["54", "Spine"],
    ["55", "Thigh- Left/Back"],
    ["56", "Thigh- Left/Front"],
    ["57", "Thigh- Right/Back"],
    ["58", "Thigh- Right/Front"],
    ["59", "Toes- Left"],
    ["60", "Toes- Right"],
    ["61", "Trochanter- Left"],
    ["62", "Trochanter- Right"],
    ["63", "Upper Arm- Left/Front"],
    ["64", "Upper Arm- Left/Back"],
    ["65", "Upper Arm- Right/Back"],
    ["66", "Upper Arm- Right/Front"],
    ["70", "Forehead"],
    ["71", "Ant Abdomen"],
    ["72", "Back - Middle"],
    ["73", "Genitalia"],
    ["74", "Mouth"]
]);
