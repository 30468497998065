import { Pagination } from "react-bootstrap";
import useYearQuarter from "../../hooks/useYearQuarter";

export default function YearQuarterPicker() {
    const { yearQuarter, nextYearQuarter, previousYearQuarter } = useYearQuarter(); 
    return (
        <Pagination className="unselectable">
            <Pagination.Prev onClick={ previousYearQuarter } />
            <Pagination.Item>{yearQuarter.toString()}</Pagination.Item>
            <Pagination.Next onClick={ nextYearQuarter } />
        </Pagination>
    );
}
