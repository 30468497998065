import { Link, Route, Switch, useParams } from "react-router-dom";
import Obfuscated from "../obfuscation/Obfuscated";
import { Spinner, Badge, ListGroup, ListGroupItem, Card, Container, Row, Col } from "react-bootstrap";
import PatientSurveyDetails from "./PatientSurveyDetails";
import Patient from "./patient";
import { useUnit } from "../../hooks/useUnit";

const includedInSurveyCodes = ["N", "M", "P", "CP", "C"];

export default function UnitDetail() {
  const { loading, unit } = useUnit();

  const { unitDataId, surveyId } = useParams<{ unitDataId: string, surveyId: string }>();
  let id = parseInt(unitDataId);
  let surveyIdInt = parseInt(surveyId);

  function simplifyStatusCode(statusCode: string) {
    let simplifiedCode, displayType;
    if (statusCode.match(/^(M)$/)) {
      simplifiedCode = 'Done/Without Wounds';
      displayType = 'info';
    }
    else if (statusCode.match(/^(CP)$/)) {
      simplifiedCode = 'Not Done/With Wounds';
      displayType = 'danger';
    }
    else if (statusCode.match(/^(P)$/)) {
      simplifiedCode = 'Not Done/Without Wounds';
      displayType = 'dark';
    }
    else if (statusCode.match(/^(C)$/)) {
      simplifiedCode = 'Done/With Wounds';
      displayType = 'warning';
    }
    else if (statusCode.match(/^(R|RP|A|AP|U|UP|D|DP|S|SP)$/)) {
      simplifiedCode = 'No Survey';
      displayType = 'secondary';
    }
    else {
      simplifiedCode = 'Ready/Not Started';
      displayType = 'success';
    }
    return (
      <Badge className="mb-1" pill bg={displayType}>{simplifiedCode}</Badge>
    );
  }

  if (loading)
    return (
      <div className="loading-screen">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );

  if (!unit)
    return (<h1>Unit Not Found!</h1>);

  const numIncludedInSurvey = unit.Patient_Info.filter(p => p.Survey_Status_Code && includedInSurveyCodes.includes(p.Survey_Status_Code)).length;
  const numInProcessWithUlcers = unit.Patient_Info.filter(p => p.Survey_Status_Code === "CP").length;
  const numCompleteWithoutUlcers = unit.Patient_Info.filter(p => p.Survey_Status_Code === "M").length;

  return (
    <Switch>
      <Route path={[`/units/:unitDataId/patient/:surveyId/ulcers/location/:locationId`, `/units/:unitDataId/patient/:surveyId/ulcers`]}>
        <Patient />
      </Route>
      <Route>
        <Container>
          <Row className="my-3">
            <Col>
              <Link to="/units">All Units</Link>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <h1>Unit: {unit.Unit_Name}</h1>
            </Col>
            <Col lg={8}>
              <ListGroup horizontal className="header-counts justify-content-lg-end">
                <ListGroup.Item>
                  <Badge bg="secondary">{numIncludedInSurvey}</Badge> Total<br />Patients
                </ListGroup.Item>
                <ListGroup.Item>
                  <Badge bg="success">{unit.Num_Not_seen}</Badge> Ready/Not <br /> Started
                </ListGroup.Item>
                <ListGroup.Item>
                  <Badge bg="danger">{numInProcessWithUlcers}</Badge> Not Done<br />With Wounds
                </ListGroup.Item>
                <ListGroup.Item>
                  <Badge bg="warning">{unit.Num_Complete_with_Ulcers}</Badge> Done With<br />Wounds
                </ListGroup.Item>
                <ListGroup.Item>
                  <Badge bg="info">{numCompleteWithoutUlcers}</Badge> Done Without<br />Wounds
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row className="my-4">
            <Col xs={6} md={4} lg={4} xl={4} className="mb-4">
              <ul className="scrollable-list">
                {unit.Patient_Info.map(p => (
                  <li key={p.Survey_ID}>
                    <Card
                      key={p.Survey_ID}
                      className={surveyIdInt === p.Survey_ID ? "active-patient w-100" : "w-100"}
                      as={Link}
                      to={`/units/${id}/patient/${p.Survey_ID}`}
                    >
                      <Card.Body>
                        <Row>
                          <Col>
                            {simplifyStatusCode(`${p.Survey_Status_Code}`)}
                          </Col>
                        </Row>
                        <Card.Title className="d-flex justify-content-between m-0">
                          <Obfuscated>
                            {p.Pat_Name}
                          </Obfuscated>
                        </Card.Title>
                        <ListGroup variant="flush">
                          <ListGroupItem>
                            <Row>
                              <Col md={6}>
                                MRN <Obfuscated>{p.MRN}</Obfuscated>
                              </Col>
                              <Col md={6}>
                                Age/Sex <Obfuscated>{p.Age_Sex}</Obfuscated>
                              </Col>
                              <Col md={6}>
                                Room/Bed {p.Room_Bed}
                              </Col>
                            </Row>
                          </ListGroupItem>
                        </ListGroup>
                      </Card.Body>
                    </Card>
                  </li>
                ))}
              </ul>
            </Col>
            <Col xs={6} md={8} lg={8} xl={8}>
              <Switch>
                <Route path={`/units/:unitDataId/patient/:surveyId`}>
                  <PatientSurveyDetails />
                </Route>
              </Switch>
            </Col>
          </Row>
        </Container >
      </Route>
    </Switch>
  );
}
