import { useCallback, useMemo } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { ulcerLocations } from "../../lib/staticData/ulcerLocation";
import UlcerEdit from "./UlcerEdit";
import { usePatient } from "../../hooks/usePatient";

export default function UlcerLocation() {
    const history = useHistory();
    const { unitDataId, locationId, ulcerInstance } = useParams<{ unitDataId: string, locationId: string, ulcerInstance: string }>();
    const { patient, addUlcer, deleteUlcer } = usePatient();
    const ulcer = useMemo(() => {
        return patient?.Ulcer_Info?.find(u => u.Location === locationId && u.Instance === parseInt(ulcerInstance));
    }, [patient, locationId, ulcerInstance]);

    const addAndSelectUlcer = useCallback(() => {
        if (!patient) {
            return;
        }
        const ulcerInfo = patient.Ulcer_Info ?? [];
        const maxInstance = Math.max(...ulcerInfo.filter(u => u.Location === locationId).map(u => u.Instance), 0);
        history.push(`/units/${unitDataId}/patient/${patient.Survey_ID}/ulcers/location/${locationId}/injury/${maxInstance + 1}`);
        addUlcer(locationId);
    }, [addUlcer, locationId, patient, history, unitDataId]);

    const deleteAndSelectUlcer = useCallback(() => {
        if (!patient?.Ulcer_Info || !ulcer) {
            return;
        }
        const maxInstance = Math.max(...patient.Ulcer_Info.filter(u => u.Location === ulcer.Location).map(u => u.Instance));
        const newInstance = Math.min(ulcer.Instance, maxInstance - 1);
        deleteUlcer(ulcer);
        history.push(`/units/${unitDataId}/patient/${patient.Survey_ID}/ulcers/location/${ulcer.Location}${newInstance ? `/injury/${newInstance}` : ""}`);
    }, [deleteUlcer, history, ulcer, patient, unitDataId]);

    const returnToPatient = useCallback(() => {
        if (!patient) {
            return;
        }

        history.push(`/units/${unitDataId}/patient/${patient.Survey_ID}`);
    }, [history, unitDataId, patient]);

    if (!ulcerLocations.has(locationId)) {
        return <div>Invalid location</div>;
    }

    if (!patient) {
        return <div>Patient not found</div>
    }

    return (
        <Container>
            <Row>
                <Col>
                    <h2 className="mb-3">{ulcerLocations.get(locationId)}</h2>
                </Col>
            </Row>
            {
                (patient!.Ulcer_Info || []).filter(u => u.Location === locationId).length > 0 &&
                <Row>
                    <Col>
                        <Form.Label>Injury Selector:</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={(evt) => history.push(`/units/${unitDataId}/patient/${patient.Survey_ID}/ulcers/location/${locationId}/injury/${evt.target.value}`)}
                            value={ulcer?.Instance || 0}
                            className="mb-4 form-select"
                        >
                            <option disabled value="0">Select an Injury</option>
                            {patient!.Ulcer_Info!.filter(u => u.Location === locationId).map(u => {
                                return <option key={u.Instance} value={u.Instance}>{`Injury ${u.Instance}`}</option>;
                            })}
                        </Form.Control>
                    </Col>
                </Row>
            }
            {ulcer && <UlcerEdit ulcer={ulcer} />}
            <Row>
                <Col sm={12} md={ulcer ? 6 : 12} className="d-grid gap-2 mb-2">
                    <Button disabled={patient.Readonly} variant="primary" onClick={addAndSelectUlcer}>Add New Injury</Button>
                </Col>
                {ulcer &&
                    <Col sm={12} md={6} className="d-grid gap-2 mb-2">
                        <Button
                            variant="outline-danger"
                            onClick={deleteAndSelectUlcer}
                        >Delete Injury</Button>
                    </Col>
                }
            </Row>
            <Row>
                <Col className="d-grid gap-2 mb-2">
                    <Button onClick={returnToPatient}>
                        {ulcer && 'Save & '}
                        Return to Patient</Button>
                </Col>
            </Row>
        </Container >
    );
}
