import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import Patient_Details from "../models/Patient_Details";
import Unit_Patient_Info from "../models/Unit_Patient_Info";
import YearQuarter from "../models/YearQuarter";
import useApi from "./useApi";
import useYearQuarter from "./useYearQuarter";

export interface UnitContextState {
    loading: boolean,
    unit: Unit_Patient_Info | null,
    updatePatientInUnit(patient: Patient_Details): void
};

export const UnitContext = createContext(null as UnitContextState | null);

export function useUnit() {
    const currentUnit = useContext(UnitContext);
    if (!currentUnit) throw new Error("Unit Provider not found!");
    return currentUnit;
}

export function UnitProvider(props: { children: any }) {
    const api = useApi();
    const { setYearQuarter } = useYearQuarter();
    const unitDataId = parseInt(useParams<{ unitDataId: string }>().unitDataId);
    const [unit, setUnit] = useState(null as Unit_Patient_Info | null);
    const [loading, setLoading] = useState(!!unitDataId);

    const updatePatientInUnit = useCallback((patient: Patient_Details) => {
        setUnit(u => {
            if (!u) {
                return null;
            }
            return { ...u, Patient_Info: u.Patient_Info.map(p => p.Survey_ID === patient.Survey_ID ? patient : p) };
        });
    }, []);

    useEffect(() => {
        if (!unitDataId) {
            setUnit(null);
            return;
        }
        async function getUnit() {
            if (unitDataId) {
                setLoading(true);
                setUnit(await api.PatientDetail(unitDataId));
                setLoading(false);
            }
        }
        getUnit();
    }, [unitDataId, api]);

    useEffect(() => {
        if (unit) {
            setYearQuarter(YearQuarter.fromValue(unit.YearQuarter));
        }
    }, [unit, setYearQuarter])

    let value = useMemo(() => ({
        loading,
        unit,
        updatePatientInUnit
    }), [loading, unit, updatePatientInUnit])

    return (
        <UnitContext.Provider value={value}>
            {props.children}
        </UnitContext.Provider>
    );
}
