import { usePrevious } from "@reactivers/use-previous";
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { statusCodes } from "../lib/staticData/statusCodes";
import Patient_Details from "../models/Patient_Details";
import Ulcer from "../models/Ulcer";
import useApi from "./useApi";
import { useUnit } from "./useUnit";

export interface PatientContextState {
    patient: Patient_Details | null,
    updatePatient(updatedPatient: Partial<Patient_Details>): void,
    addUlcer(locationId: string): void,
    updateUlcer(ulcer: Ulcer): void,
    deleteUlcer(ulcer: Ulcer): void
};

export const PatientContext = createContext(null as PatientContextState | null);

export function usePatient() {
    const currentPatient = useContext(PatientContext);
    if (!currentPatient) throw new Error("Patient Provider not found!");
    return currentPatient;
}

export function PatientProvider(props: { children: any, onUpdated: () => void }) {
    const api = useApi();
    const { unit, updatePatientInUnit } = useUnit();
    const surveyId = parseInt(useParams<{ surveyId: string }>().surveyId);
    const previousSurveyId = usePrevious(surveyId);
    const [patient, setPatient] = useState(null as Patient_Details | null);
    const [dirty, setDirty] = useState(false);
    const [changesPending, setChangesPending] = useState(false);
    const [loadedFirstPatient, setLoadedFirstPatient] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null as NodeJS.Timeout | null);
    const { onUpdated } = props;
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => { mounted.current = false };
    }, [])

    useEffect(() => {
        async function savePatient(patient: Patient_Details) {
            await api.UpdatePatient(patient);
        }

        if (surveyId === previousSurveyId && loadedFirstPatient) {
            if (patient && dirty) {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                setDirty(false);
                setTimeoutId(setTimeout(async () => {
                    if (mounted.current) {
                        setChangesPending(false);
                    }
                    await savePatient(patient);
                    onUpdated();
                }, 2000));
            }
            return;
        }

        if (patient) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            if (changesPending) {
                setChangesPending(false);
                savePatient(patient);
            }
        }

        if (unit && surveyId) {
            setPatient(unit?.Patient_Info.find(p => p.Survey_ID === surveyId) || null);
            setLoadedFirstPatient(true);
        }

    }, [patient, surveyId, previousSurveyId, dirty, changesPending, loadedFirstPatient, timeoutId, unit, api, onUpdated]);

    useEffect(() => {
        if (dirty) {
            if (patient) {
                updatePatientInUnit(patient);
            }
            setChangesPending(true);
        }
    }, [dirty, patient, updatePatientInUnit])

    const updatePatient = useCallback((updatedPatient: Partial<Patient_Details>) => {
        setPatient(p => {
            if (!p) {
                return p;
            }
            if (!updatedPatient.Survey_Status_Code?.includes("C")) {
                updatedPatient.Num_Ulcers = 0;
                updatedPatient.Ulcer_Info = null;
            }
            if (updatedPatient.Survey_Status_Code) {
                updatedPatient.Status_Description = statusCodes.get(updatedPatient.Survey_Status_Code)?.Description || p.Status_Description;
            }
            return { ...p, ...updatedPatient };
        });
        setDirty(true);
    }, []);

    const addUlcer = useCallback((locationId: string) => {
        setPatient(p => {
            if (!p) {
                return p;
            }

            const ulcers = p.Ulcer_Info || [];
            const ulcersAtThisLocation = ulcers.filter(u => u.Location === locationId);
            const maxId = Math.max(0, ...ulcers.map(u => u.Ulcer_Record_ID));
            const maxInstance = Math.max(0, ...ulcersAtThisLocation.map(u => u.Instance));
            const newStatusCode = p.Survey_Status_Code === "N" ? "CP" : "C";
            return {
                ...p,
                Ulcer_Info: [
                    ...ulcers,
                    {
                        Device_Notes: "",
                        Device_Related: false,
                        Hospital_Location_Acquired: "",
                        Instance: maxInstance + 1,
                        Location: locationId,
                        Stage: 0, // Unstaged
                        Ulcer_Notes: "",
                        Ulcer_Record_ID: maxId + 1,
                        Where_Acquired: 3, // Unknown
                        survey_ID: p!.Survey_ID
                    }
                ],
                Survey_Status_Code: newStatusCode,
                Num_Ulcers: p.Num_Ulcers + 1
            }
        });
        setDirty(true);
    }, []);

    const updateUlcer = useCallback((ulcer: Ulcer) => {
        setPatient(p => {
            if (!p?.Ulcer_Info) {
                return p;
            }
            return {
                ...p!,
                Ulcer_Info: p.Ulcer_Info
                    .map(u => {
                        if (u.Location === ulcer.Location && u.Ulcer_Record_ID === ulcer.Ulcer_Record_ID)
                        {
                            return ulcer;
                        }

                        return u;
                    }),
            }
        });
        setDirty(true);
    }, []);

    const deleteUlcer = useCallback((ulcer: Ulcer) => {
        setPatient(p => {
            if (!p?.Ulcer_Info) {
                return p;
            }

            const newUlcerInfo = p.Ulcer_Info!
                .filter(u => u.Location !== ulcer.Location || u.Instance !== ulcer.Instance)
                .map(u => ({
                    ...u, Instance: u.Instance - (u.Instance > ulcer.Instance && u.Location === ulcer.Location ? 1 : 0)
                }));

            let newStatusCode = p.Survey_Status_Code;

            if (newUlcerInfo.length === 0) {
                newStatusCode = p.Survey_Status_Code === "CP" ? "N" : "M";
            }

            return {
                ...p,
                Ulcer_Info: newUlcerInfo,
                Survey_Status_Code: newStatusCode,
                Num_Ulcers: Math.max(p.Num_Ulcers - 1, 0)
            }
        });
        setDirty(true);
    }, []);

    const value = useMemo(
        () => {
            return {
                patient,
                updatePatient,
                addUlcer,
                updateUlcer,
                deleteUlcer
            };
        }
        , [patient, updatePatient, addUlcer, updateUlcer, deleteUlcer]
    );

    return (
        <PatientContext.Provider value={value}>
            {props.children}
        </PatientContext.Provider>
    );
}
