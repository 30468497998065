interface ChangeURLFormElements extends HTMLFormControlsCollection {
    url: HTMLInputElement;
}

interface ChangeURLFormElement extends HTMLFormElement {
    readonly elements: ChangeURLFormElements;
}

export default function ApiNotConnectedPage(props: {setBackendUrl(url: string): void}) {
    const handleSubmit = async (e: React.FormEvent<ChangeURLFormElement>) => {
        e.preventDefault();

        const { currentTarget } = e;
        const { url } = currentTarget.elements;

        await props.setBackendUrl(url.value);
    };

    return (
        <div>
            <h1>API not connected!</h1>
            <p>Set endpoint below</p>
            <form onSubmit={handleSubmit}>
                <label htmlFor="url">API URL:</label>
                <input id="url" name="url"></input>
                <button type="submit">Change backend URL</button>
            </form>
        </div>
    );
}
