export default function PatientBack() {
    return (
        <>
            <g>
                <path d="m61.751 157.07c1.0824-3e-5 2.1649-1e-5 3.2473 0 0 0-1.6237-1.3758-1.6237-6.8576 0 5.4817-1.6237 6.8576-1.6237 6.8576z" />
            </g>
            <g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
                <path d="m88.244 84.64c0.0935 1.1225 5.5302 16.405 6.0336 17.937 0.54543 1.66 1.1746 9.3344 2.3854 13.891 1.0112 3.8057 6.1272 15.575 7.7174 19.364 0.93452 2.2264-0.56126 5.753-0.37418 7.0158 0.18621 1.2569 2.7596 5.6594 2.8999 8.7464s1.029 9.027 2.4789 9.0738 1.0758-3.695 0.37418-8.7464c0.46772 4.3498 2.666 10.15 3.8821 9.9625s0.65481-5.1449-0.32741-10.29c0.60804 3.3208 3.357 10.728 4.9111 10.196 1.6374-0.56 0.28063-5.5659-1.7306-11.74 0.5145 1.3564 5.332 10.524 7.0158 9.8689 1.6838-0.6548-2.2451-8.9802-4.419-13.905 1.3229 0.0662 2.5689 2.6244 4.2307 4.0703 1.7124 1.49 3.6687 0.32741 2.6532-2.2957-0.88749-2.2923-6.2838-8.8635-7.3422-9.9219-1.0583-1.0583-4.2333-2.6458-4.9609-3.7042-0.72761-1.0583-2.6458-23.283-3.3073-26.26-0.66146-2.9766-5.3186-13.888-5.1423-14.728 0.17596-0.83764-2.0131-20.437-3.7531-30.257-1.4371-8.1099-5.8085-13.88-11.167-15.86-5.0789-1.877-4.9438-1.9549-9.6351-3.4611-6.9924-2.2451-9.6834-8.6242-7.9426-10.244 1.8319-1.7047 3.9259-5.3039 4.3649-8.9165" />
                <path d="m91.798 66.235c-2.6192 12.161-5.2854 26.65-5.0982 33.858 0.19263 7.4163 4.7021 11.815 3.9369 21.662-0.47867 6.1594 2.0627 14.047 2.4241 21.093 0.44586 8.6913-1.7773 23.667-3.274 33.489-1.4967 9.8222-5.7998 26.754-5.8465 30.589-0.0468 3.835 0.21333 6.2451 0.61607 13.945 0.36411 6.9612-7.0872 32.825-7.4061 36.095-0.33589 3.4448 1.8321 10.951 3.6095 15.535 1.7773 4.5837 3.2183 7.9265 3.5427 9.251 0.19843 0.81029 0.1819 2.7947-0.54571 3.175-0.7276 0.38034-1.5379 0.1819-1.7694-1.8356 0.26459 2.1167 0.64334 3.3268-0.19843 3.7042-0.47956 0.21497-1.9182 0.13229-2.1497-2.9766 0.31419 1.8852 0.41341 3.9522-0.678 4.1672-1.2284 0.24196-1.9844-0.87644-2.0836-3.7372 0.19844 2.1663 0.94258 4.5641-0.74414 4.6798-1.8184 0.12479-2.0836-1.4221-2.1663-4.2499 0.0662 2.1828 0.62839 4.6302-0.99219 4.746-1.6206 0.11575-3.0374-1.3749-2.927-3.4065 0.16535-3.0427-1.3587-4.1424-1.1245-7.6932 0.70849-10.739-3.4602-12.935-2.566-18.263 0.49264-2.9351 1.7737-4.3525 1.2592-10.667-0.38484-4.7231-2.4148-26.974-1.1225-33.629 0.67463-3.4742 1.5903-15.856 1.0758-18.802-0.5145-2.9466-2.9934-29.56-2.5725-39.897" />
                <path d="m34.95 66.235c2.6192 12.161 5.2854 26.65 5.0982 33.858-0.19263 7.4163-4.7021 11.815-3.9369 21.662 0.47867 6.1594-2.0627 14.047-2.4241 21.093-0.44586 8.6913 1.7773 23.667 3.274 33.489 1.4967 9.8222 5.7998 26.754 5.8465 30.589 0.0468 3.835-0.21333 6.2451-0.61607 13.945-0.36411 6.9612 7.0872 32.825 7.4061 36.095 0.33589 3.4448-1.8321 10.951-3.6095 15.535-1.7773 4.5837-3.2183 7.9265-3.5427 9.251-0.19843 0.81029-0.1819 2.7947 0.54571 3.175 0.7276 0.38034 1.5379 0.1819 1.7694-1.8356-0.26459 2.1167-0.64334 3.3268 0.19843 3.7042 0.47956 0.21497 1.9182 0.13229 2.1497-2.9766-0.31419 1.8852-0.41341 3.9522 0.678 4.1672 1.2284 0.24196 1.9844-0.87644 2.0836-3.7372-0.19844 2.1663-0.94258 4.5641 0.74414 4.6798 1.8184 0.12479 2.0836-1.4221 2.1663-4.2499-0.0662 2.1828-0.62839 4.6302 0.99219 4.746 1.6206 0.11575 3.0374-1.3749 2.927-3.4065-0.16535-3.0427 1.3587-4.1424 1.1245-7.6932-0.70849-10.739 3.4602-12.935 2.566-18.263-0.49264-2.9351-1.7737-4.3525-1.2592-10.667 0.38485-4.7231 2.4148-26.974 1.1225-33.629-0.67463-3.4742-1.5903-15.856-1.0758-18.802 0.5145-2.9466 2.9934-29.56 2.5725-39.897" />
                <path d="m50.14 27.366c-3.9688 0-4.1341-4.8901-4.1341-6.5201 0-1.63 0.21262-3.7089 3.5292-3.695l9e-3 -4.7576c0-9.4213 6.3476-11.893 13.831-11.893 7.4836 0 13.831 2.4721 13.831 11.893l9e-3 4.7576c3.3166-0.01392 3.5292 2.065 3.5292 3.695 0 1.63-0.16537 6.5201-4.1341 6.5201" />
                <path d="m38.505 84.64c-0.0935 1.1225-5.5302 16.405-6.0336 17.937-0.54543 1.66-1.1746 9.3344-2.3854 13.891-1.0112 3.8057-6.1272 15.575-7.7174 19.364-0.93452 2.2264 0.56126 5.753 0.37418 7.0158-0.18621 1.2569-2.7596 5.6594-2.8999 8.7464s-1.029 9.027-2.4789 9.0738-1.0758-3.695-0.37418-8.7464c-0.46772 4.3498-2.666 10.15-3.8821 9.9625s-0.65481-5.1449 0.32741-10.29c-0.60804 3.3208-3.357 10.728-4.9111 10.196-1.6374-0.56-0.28063-5.5659 1.7306-11.74-0.5145 1.3564-5.332 10.524-7.0158 9.8689-1.6838-0.6548 2.2451-8.9802 4.419-13.905-1.3229 0.0662-2.5689 2.6244-4.2307 4.0703-1.7124 1.49-3.6687 0.32741-2.6532-2.2957 0.88749-2.2923 6.2838-8.8635 7.3422-9.9219 1.0583-1.0583 4.2333-2.6458 4.9609-3.7042 0.72761-1.0583 2.6458-23.283 3.3073-26.26 0.66146-2.9766 5.3186-13.888 5.1423-14.728-0.17596-0.83764 2.0131-20.437 3.7531-30.257 1.4371-8.1099 5.8085-13.88 11.167-15.86 5.0789-1.877 4.9438-1.9549 9.6351-3.4611 6.9924-2.2451 9.6834-8.6242 7.9426-10.244-1.8319-1.7047-3.9259-5.3039-4.3649-8.9165" />
                <path d="m68.708 261.34c1.029-1.1225 1.4733-1.7072 4.4901-0.93544" />
                <path d="m58.04 261.34c-1.029-1.1225-1.4733-1.7072-4.4901-0.93544" />
                <path d="m72.053 207.27c1.8709-0.56127 5.9401-0.58465 7.694 0.0234" />
                <path d="m54.696 207.27c-1.8709-0.56127-5.9401-0.58465-7.694 0.0234" />
                <path d="m82.668 159.14c-2.3386 0.4911-19.294 6.782-19.294-8.9335 0 15.715-16.955 9.4246-19.294 8.9335" />
                <path d="m54.443 126.75c-5.0982-4.116-9.3077-5.0982-13.611-4.5837" strokeDasharray="3, 3" />
                <path d="m72.306 126.75c5.0982-4.116 9.3077-5.0982 13.611-4.5837" strokeDasharray="3, 3" />
                <path d="m71.187 59.307c-3.1805 13.47-0.3274 18.615 3.2273 23.152" strokeDasharray="3, 3" />
                <path d="m55.562 59.307c3.1805 13.47 0.3274 18.615-3.2273 23.152" strokeDasharray="3, 3" />
                <path d="m101.07 94.76c3.087 3.5547 3.087 7.1094 3.0402 9.9157" strokeDasharray="2, 2" />
                <path d="m95.976 95.602c-0.0468 5.0514 1.4967 8.7932 2.9934 10.196" strokeDasharray="2, 2" />
                <path d="m25.674 94.76c-3.087 3.5547-3.087 7.1094-3.0402 9.9157" strokeDasharray="2, 2" />
                <path d="m30.772 95.602c0.0468 5.0514-1.4967 8.7932-2.9934 10.196" strokeDasharray="2, 2" />
            </g>
        </>
    );
}
