import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import useAuth from './hooks/useAuth';
import Logout from './components/auth/Logout';
import { Redirect, Route, Switch } from 'react-router-dom';
import UnitList from './components/units/list';
import UnitDetail from './components/units/detail';
import ToggleObfuscation from './components/obfuscation/ToggleObfuscation';
import AddPartyMembers from './components/auth/AddPartyMembers';
import { Container, Row, Col, Button } from "react-bootstrap";
import { UnitProvider } from './hooks/useUnit';
import { PatientProvider } from './hooks/usePatient';
import { useCallback, useState } from 'react';

function App() {
  const { user, partyMembers } = useAuth();
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const onUpdated = useCallback(() => {
    setLastUpdated(new Date());
  }, []);

  return (
    <div>
      <header>
        <Container className="py-2">
          <Row>
            <Col>
              <span className="align-middle">Welcome, {[user, ...partyMembers].map(au => au.displayName).join(", ")}</span>
              <Logout />
            </Col>
            <Col className="text-end">
              <Button variant="primary" size="sm" className="mx-2" href="/partymembers">Manage Party</Button>
              <ToggleObfuscation />
            </Col>
          </Row>
        </Container>
      </header>
      <Container className="main-content">
        <Switch>
          <Route path="/" exact>
            <Redirect to="/units" />
          </Route>
          <Route path="/units" exact>
            <UnitList lastUpdated={lastUpdated} />
          </Route>
          <Route path={["/units/:unitDataId/patient/:surveyId", "/units/:unitDataId"]}>
            <UnitProvider>
              <PatientProvider onUpdated={onUpdated}>
                <UnitDetail />
              </PatientProvider>
            </UnitProvider>
          </Route>
          <Route path="/partymembers">
            <AddPartyMembers />
          </Route>
        </Switch>
      </Container>
    </div>
  );
}

export default App;
