import StatusCode from "../../models/StatusCode";

export const statusCodes = new Map<string, StatusCode>([
    ["A", {
        "Description": "Absent from unit",
        "SurveY_Status_ID": 6,
        "Survey_done": false,
        "Patient_Seen": true,
        "Sort_order": 1,
        "Survey_Status": "A"
    }],
    ["AP", {
        "Description": "Absent (in process)",
        "SurveY_Status_ID": 12,
        "Survey_done": false,
        "Patient_Seen": false,
        "Sort_order": 9,
        "Survey_Status": "AP"
    }],
    ["C", {
        "Description": "Complete, 1+  Pressure Injuries  Present",
        "SurveY_Status_ID": 3,
        "Survey_done": true,
        "Patient_Seen": true,
        "Sort_order": 8,
        "Survey_Status": "C"
    }],
    ["CP", {
        "Description": "1+ Ulcers (in process)",
        "SurveY_Status_ID": 1011,
        "Survey_done": false,
        "Patient_Seen": false,
        "Sort_order": 14,
        "Survey_Status": "CP"
    }],
    ["D", {
        "Description": "Dying",
        "SurveY_Status_ID": 7,
        "Survey_done": false,
        "Patient_Seen": true,
        "Sort_order": 5,
        "Survey_Status": "D"
    }],
    ["DP", {
        "Description": "Dying (in process)",
        "SurveY_Status_ID": 16,
        "Survey_done": false,
        "Patient_Seen": false,
        "Sort_order": 13,
        "Survey_Status": "DP"
    }],
    ["M", {
        "Description": "Complete, No Pressure Injuries Found",
        "SurveY_Status_ID": 8,
        "Survey_done": true,
        "Patient_Seen": true,
        "Sort_order": 7,
        "Survey_Status": "M"
    }],
    ["N", {
        "Description": "Not yet surveyed",
        "SurveY_Status_ID": 1,
        "Survey_done": false,
        "Patient_Seen": false,
        "Sort_order": 100,
        "Survey_Status": "N"
    }],
    ["P", {
        "Description": "In Process",
        "SurveY_Status_ID": 10,
        "Survey_done": false,
        "Patient_Seen": false,
        "Sort_order": 6,
        "Survey_Status": "P"
    }],
    ["R", {
        "Description": "Refused",
        "SurveY_Status_ID": 5,
        "Survey_done": false,
        "Patient_Seen": true,
        "Sort_order": 3,
        "Survey_Status": "R"
    }],
    ["RP", {
        "Description": "Refused (in process)",
        "SurveY_Status_ID": 14,
        "Survey_done": false,
        "Patient_Seen": false,
        "Sort_order": 11,
        "Survey_Status": "RP"
    }],
    ["S", {
        "Description": "Discharged",
        "SurveY_Status_ID": 9,
        "Survey_done": false,
        "Patient_Seen": true,
        "Sort_order": 2,
        "Survey_Status": "S"
    }],
    ["SP", {
        "Description": "Discharge (in process)",
        "SurveY_Status_ID": 13,
        "Survey_done": false,
        "Patient_Seen": false,
        "Sort_order": 10,
        "Survey_Status": "SP"
    }],
    ["U", {
        "Description": "Unsafe",
        "SurveY_Status_ID": 4,
        "Survey_done": false,
        "Patient_Seen": true,
        "Sort_order": 4,
        "Survey_Status": "U"
    }],
    ["UP", {
        "Description": "Unsafe (in process)",
        "SurveY_Status_ID": 15,
        "Survey_done": false,
        "Patient_Seen": false,
        "Sort_order": 12,
        "Survey_Status": "UP"
    }]
]);
