export const ulcerStages = new Map([
    [0, "Unstaged"],
    [1, "Stage 1"],
    [2, "Stage 2"],
    [3, "Stage 3"],
    [4, "Stage 4"],
    [5, "DTI (Deep Tissue Injury)"],
    [6, "Unstageable"],
    [7, "Indeterminate"],
    [8, "Mucosal"],
    [9, "Healing full thickness"]
]);
