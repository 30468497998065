import { createContext, useContext, useMemo, useState } from "react";
import YearQuarter from "../models/YearQuarter";

export interface YearQuarterContextState {
    yearQuarter: YearQuarter,
    nextYearQuarter(): void,
    previousYearQuarter(): void,
    setYearQuarter(yearQuarter: YearQuarter): void
}

export const YearQuarterContext = createContext(null as YearQuarterContextState | null);

export default function useYearQuarter() {
  const quarter = useContext(YearQuarterContext);
  if (!quarter) throw new Error("YearQuarter Provider not found!");
  return quarter;
}

export function YearQuarterProvider(props: {children: any}) {
    const [yearQuarter, setYearQuarter] = useState(YearQuarter.fromDate(new Date()));

    function nextYearQuarter() {
        setYearQuarter(q => q.next());
    }
    function previousYearQuarter() {
        setYearQuarter(q => q.previous());
    }

    let value = useMemo(() => ({
        yearQuarter,
        nextYearQuarter,
        previousYearQuarter,
        setYearQuarter
    }), [yearQuarter])

    return (
        <YearQuarterContext.Provider value={value}>
            { props.children }
        </YearQuarterContext.Provider>
    );
}
