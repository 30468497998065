import useAuth from "../../hooks/useAuth";
import Button from "react-bootstrap/Button";

export default function Logout() {
  const { logout } = useAuth();

  function handleLogout() {
    logout();
  }

  return (
    <Button
      onClick={handleLogout}
      variant="outline-primary"
      size="sm"
      className="mx-2"
    >
      Log Out
    </Button>
  );
}
