export const rawStatuses = new Map<string, string>([
    [
        "R", "Refused"
    ],
    [
        "A", "Absent"
    ],
    [
        "U", "Unsafe"
    ],
    [
        "D", "Dying"
    ],
    [
        "S", "Discharged"
    ],
    [
        "M", "No Injuries"
    ],
    [
        "C", "Has Injuries"
    ]
]);
