import { useState } from "react";
import { LoginService } from "../../hooks/useAuth";
import { Container, Row, Col, Form, Alert, Button } from "react-bootstrap";

interface LoginFormElements extends HTMLFormControlsCollection {
  username: HTMLInputElement;
  password: HTMLInputElement;
}

interface LoginFormElement extends HTMLFormElement {
  readonly elements: LoginFormElements;
}

export default function Login(props: LoginService) {
  const { login } = props;
  const [authMessage, setAuthMessage] = useState('Welcome! Please log in.');
  const [authStyle, setAuthStyle] = useState('light');

  const handleSubmit = async (e: React.FormEvent<LoginFormElement>) => {
    e.preventDefault();

    const { currentTarget } = e;
    const { username, password } = currentTarget.elements;

    if (!await login(username.value, password.value)) {
      currentTarget.reset();
      username.focus();
      setAuthMessage('Invalid username or password.');
      setAuthStyle('danger');
    }
  };

  return (
    <Container>
      <Row className="justify-content-center full-screen-height">
        <Col xs={10} md={6} lg={5} xl={4} className="align-self-center">
          <Form className="panel p-4" onSubmit={handleSubmit}>
            <Alert variant={authStyle} className="text-center">{authMessage}</Alert>
            <Form.Group>
              <Form.FloatingLabel controlId="username" label="Username" className="mb-4">
                <Form.Control type="text" name="username" required autoFocus placeholder="Username" />
              </Form.FloatingLabel>
            </Form.Group>
            <Form.Group>
              <Form.FloatingLabel controlId="password" label="Password" className="mb-4">
                <Form.Control type="password" name="password" required placeholder="Password" />
              </Form.FloatingLabel>
            </Form.Group>
            <div className="d-grid">
              <Button size="lg" type="submit">Log In</Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
