import { useEffect, useState } from "react";
import { Alert, Button, ButtonGroup, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { rawStatuses } from "../../lib/staticData/rawStatuses";
import { riskScale } from "../../lib/staticData/riskScale";
import Obfuscated from "../obfuscation/Obfuscated";
import { usePatient } from "../../hooks/usePatient";
import { useUnit } from "../../hooks/useUnit";

const autoCompleteStatusCodes = ["A", "D", "R", "S", "U", "M"];

function statusCodeToRawAndComplete(statusCode: string) {
    let rawCode = "N";
    let surveyComplete = false;
    if (statusCode.length === 1) {
        rawCode = statusCode === "P" ? "M" : statusCode;
        if (statusCode !== "P" && statusCode !== "N") {
            surveyComplete = true;
        }
    } else {
        rawCode = statusCode.substring(0, 1);
    }
    return { rawCode, surveyComplete }
}

function rawAndCompleteToStatusCode(rawStatus: string, complete: boolean) {
    if (autoCompleteStatusCodes.includes(rawStatus)) {
        return rawStatus;
    }
    if (rawStatus === "N") {
        return "M";
    }

    return rawStatus + (complete ? "" : "P");
}

export default function PatientSurveyDetails() {
    const history = useHistory();
    const [status, setStatus] = useState("N");
    const [complete, setComplete] = useState(false);
    const { unit } = useUnit();
    const { patient, updatePatient } = usePatient();
    const [warnForUlcerDeletion, setWarnForUlcerDeletion] = useState(false);
    const [statusChangeCallback, setStatusChangeCallback] = useState(() => { return () => { } });

    function ulcerDeletionWarning(callback: { (): void }) {
        if (status === "C") {
            setWarnForUlcerDeletion(true);
            setStatusChangeCallback(() => { return callback });
        } else {
            callback();
        }
    }

    useEffect(() => {
        if (patient) {
            const { rawCode, surveyComplete } = statusCodeToRawAndComplete(patient.Survey_Status_Code ?? "N");
            setStatus(rawCode);
            setComplete(surveyComplete);
        }

    }, [patient]);

    if (!unit) {
        return <div>No unit</div>;
    }

    if (!patient) {
        return <div>No patient</div>;
    }

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <h2 className="my-4">Survey Information for <Obfuscated>{patient.Pat_Name}</Obfuscated></h2>
                    </Col>
                </Row>
                {patient.Readonly && <Alert>This patient was surveyed in a previous quarter, so survey results cannot be edited.</Alert>}
                <Row>
                    <Col>
                        <Form.Label as="div" className="d-block">Select a Status:</Form.Label>
                        <ButtonGroup className="mb-4 w-100 wrap">
                            {Array.from(rawStatuses, rs => {
                                const [statusCode, statusName] = rs;
                                return (
                                    <Button
                                        key={statusCode}
                                        variant="secondary"
                                        className={`py-2 ${statusCode === status ? "active" : ""}`}
                                        disabled={patient.Readonly && !(statusCode === "C" && patient.Num_Ulcers > 0)}
                                        onClick={() => {
                                            if (statusCode === "C") {
                                                history.push(`/units/${unit.Unit_Data_ID}/patient/${patient.Survey_ID}/ulcers`);
                                                return;
                                            }
                                            ulcerDeletionWarning(() => {
                                                updatePatient({ Survey_Status_Code: rawAndCompleteToStatusCode(statusCode, complete) });
                                            });
                                        }}
                                    >
                                        {statusCode === "C" ? <>{patient.Num_Ulcers ? `View ${patient.Num_Ulcers}` : "Add"} Injuries</> : statusName}
                                    </Button>
                                );
                            })}
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row as='fieldset' disabled={patient.Readonly}>
                    <Col>
                        <Form.Check
                            className="mb-3"
                            id="formDermatitis"
                            checked={patient.Incontinence_Associated_Dermatitis}
                            onChange={evt => {
                                updatePatient({ Incontinence_Associated_Dermatitis: evt.target.checked });
                            }}
                            type="checkbox"
                            label="Incontinence Associated Dermatitis" />
                    </Col>
                </Row>
                <Row as='fieldset' disabled={patient.Readonly}>
                    <Col>
                        <Form.Check
                            className="mb-4"
                            id="formVisitedOR"
                            checked={patient.Visited_OR_this_Admission}
                            onChange={evt => {
                                updatePatient({ Visited_OR_this_Admission: evt.target.checked });
                            }}
                            type="checkbox"
                            label="Visited OR this admission" />
                    </Col>
                </Row>
                <Row as='fieldset' disabled={patient.Readonly}>
                    <Col>
                        <Form.Group className="mb-3" controlId="patientNotes">
                            <Form.Label>Patient Notes:</Form.Label>
                            <Obfuscated>
                                <Form.Control
                                    as="textarea"
                                    value={patient.Notes}
                                    onChange={(evt) => {
                                        updatePatient({ Notes: evt.target.value });
                                    }}
                                />
                            </Obfuscated>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                        <span>Risk Scale: {riskScale.get(patient.Risk_Scale)}</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Check
                            className="mb-3"
                            id="surveyComplete"
                            type="checkbox"
                            label="Survey Complete"
                            checked={complete}
                            disabled={patient.Readonly || autoCompleteStatusCodes.includes(patient.Survey_Status_Code || "N")}
                            onChange={(evt) => {
                                updatePatient({ Survey_Status_Code: rawAndCompleteToStatusCode(status, evt.target.checked) });
                            }}
                        />
                    </Col>
                </Row>
            </Container>
            <Modal show={warnForUlcerDeletion}>
                <Modal.Header closeButton onHide={() => setWarnForUlcerDeletion(false)}>
                    <Modal.Title>Remove Ulcers from Patient?</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p>
                        You are attempting to change this patient's status to
                        indicate that they do not have any pressure injuries.
                        Doing so will remove all pressure injury records from
                        the patient.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setWarnForUlcerDeletion(false)} variant="secondary">Cancel</Button>
                    <Button
                        onClick={() => {
                            setWarnForUlcerDeletion(false)
                            statusChangeCallback();
                        }}
                        variant="danger"
                    >
                        Change Patient's Status
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
