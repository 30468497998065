import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import Ulcer from "../../models/Ulcer";
import { ulcerStages } from "../../lib/staticData/ulcerStage";
import { whereAcquired } from "../../lib/staticData/whereAcquired";
import { usePatient } from "../../hooks/usePatient";

export default function UlcerEdit(props: { ulcer: Ulcer }) {
    const { patient, updateUlcer } = usePatient();
    const { ulcer } = props;

    if (!patient) {
        return null;
    }

    return (
        <fieldset disabled={patient.Readonly}>
            <Row>
                <Col>
                    <Form.Label className="d-block">Location Information:</Form.Label>
                    <ButtonGroup className="mb-4 wrap">
                        {
                            Array.from(whereAcquired, wa => {
                                const [acquiredId, acquiredDescription] = wa;
                                return (
                                    <Button
                                        key={acquiredId}
                                        variant="secondary"
                                        className={ulcer?.Where_Acquired === acquiredId ? "active" : ""}
                                        onClick={() => {
                                            updateUlcer({ ...ulcer, Where_Acquired: acquiredId });
                                        }}
                                    >
                                        {acquiredDescription}
                                    </Button>
                                );
                            })}
                    </ButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col className="mb-4">
                    <Form.Check
                        id="deviceRelated"
                        label="Device Related"
                        checked={ulcer.Device_Related}
                        onChange={(evt) => {
                            updateUlcer({ ...ulcer, Device_Related: evt.target.checked });
                        }} />
                    <Form.Label className="mt-2">Device Notes:</Form.Label>
                    <Form.Control
                        as="textarea"
                        disabled={!ulcer.Device_Related}
                        value={ulcer.Device_Notes}
                        onChange={(evt) => {
                            updateUlcer({ ...ulcer, Device_Notes: evt.target.value });
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label>Staging Information:</Form.Label>
                    <Form.Control
                        as="select"
                        value={ulcer.Stage}
                        className="form-select mb-4"
                        onChange={(evt) => {
                            updateUlcer({ ...ulcer, Stage: parseInt(evt.target.value) });
                        }}
                    >
                        {Array.from(ulcerStages, stage => {
                            const [stageId, stageDescription] = stage;
                            return <option key={stageId} value={stageId}>{stageDescription}</option>
                        })}
                    </Form.Control>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label>Injury Notes:</Form.Label>
                    <Form.Control
                        as="textarea"
                        className="mb-4"
                        value={ulcer.Ulcer_Notes}
                        onChange={(evt) => {
                            updateUlcer({ ...ulcer, Ulcer_Notes: evt.target.value });
                        }}
                    />
                </Col>
            </Row>
        </fieldset>
    );
}
