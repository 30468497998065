export default class YearQuarter {
    year: number;
    quarter: number;

    constructor(year: number, quarter: number) {
        if (quarter > 4 || quarter < 1) {
            throw new Error(`Quarter must be 1,2,3, or 4 but was ${quarter}`);
        }
        this.year = year;
        this.quarter = quarter;
    }

    next() {
        let newQuarter = this.quarter + 1;
        let newYear = this.year;
        while (newQuarter > 4) {
            newQuarter -= 4;
            newYear += 1;
        }
        return new YearQuarter(newYear, newQuarter);
    }

    previous() {
        let newYear = this.year;
        let newQuarter = this.quarter - 1;
        while (newQuarter < 1) {
            newQuarter += 4;
            newYear -= 1;
        }
        return new YearQuarter(newYear, newQuarter);
    }

    valueOf() {
        return 10 * this.year + this.quarter;
    }

    toString() {
        return `${this.year} Q${this.quarter}`;
    }

    static fromDate(date: Date) {
        return new YearQuarter(date.getFullYear(), 1 + Math.floor(date.getMonth() / 3));
    }

    static fromYearAndQuarter(year: number, quarter: number) {
        return new YearQuarter(year, quarter);
    }

    static fromValue(yearQuarterValue: number) {
        return new YearQuarter(Math.floor(yearQuarterValue / 10), (yearQuarterValue % 10));
    }
}
