import { useCallback } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import Obfuscated from "../obfuscation/Obfuscated";
import { Spinner, Col, Row, Alert, Button } from "react-bootstrap";
import UlcerLocation from "../ulcer/UlcerLocation";
import LocationMap from "../ulcer/LocationMap";
import { useUnit } from "../../hooks/useUnit";
import { usePatient } from "../../hooks/usePatient";

export default function Patient() {
    const history = useHistory();

    const { unit } = useUnit();
    const { patient } = usePatient();

    const returnToPatient = useCallback(() => {
        if (!unit || !patient) {
            return;
        }

        history.push(`/units/${unit.Unit_Data_ID}/patient/${patient.Survey_ID}`);
    }, [history, unit, patient]);

    if (!patient) {
        if (!unit) {
            return (
                <div className="loading-screen">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            );
        }
        return <div>Patient Not Found</div>
    }

    if (!unit) {
        return <div>Unit not found</div>;
    }

    return (
        <>
            <div className="subheader">
                <Row className="my-3">
                    <Col>
                        <Link to={`/units/${unit.Unit_Data_ID}/patient/${patient.Survey_ID}`} className="d-grid gap-2">
                            Return to Patient Survey
                        </Link>
                    </Col>
                </Row>
                {patient.Readonly && <Alert>This patient was surveyed in a previous quarter, so survey results cannot be edited.</Alert>}
                <Row>
                    <Col>
                        <h1><Obfuscated>{patient.Pat_Name}</Obfuscated> Injury Log</h1>
                    </Col>
                </Row>
            </div>
            <div className="content py-3">
                <Row>
                    <Col xs={6} md={6}>
                        <LocationMap unitId={unit.Unit_Data_ID} patient={patient} />
                    </Col>
                    <Col xs={6} md={6}>
                        <Switch>
                            <Route path={["/units/:unitDataId/patient/:surveyId/ulcers/location/:locationId/injury/:ulcerInstance", "/units/:unitDataId/patient/:surveyId/ulcers/location/:locationId"]}>
                                <UlcerLocation />
                            </Route>
                            <Route>
                                <Button onClick={returnToPatient}>Return to Patient</Button>
                            </Route>
                        </Switch>
                    </Col>
                </Row>

            </div>
        </>
    );
}
