import Legacy from './legacy'
import AuthUser from '../../models/AuthUser';
import Patient_Details from '../../models/Patient_Details';
import SurveyUser from '../../models/SurveyUser';
import UnitData from '../../models/UnitData';
import Unit_Patient_Info from '../../models/Unit_Patient_Info';
import YearQuarter from '../../models/YearQuarter';

export interface Api {
  Authenticate(username: string, password: string): Promise<AuthUser | null>;
  CheckConnection(): Promise<boolean>;
  DeleteDeviceUser(username: string, deviceId: string): Promise<boolean>;
  GetUser(username: string): Promise<SurveyUser | null>;
  AddDeviceUser(username: string, deviceId: string): Promise<boolean>;
  GetUnits(yearQuarter: YearQuarter): Promise<UnitData[]>;
  PatientDetail(unitDataId: number, date?: Date): Promise<Unit_Patient_Info>;
  PatientBind(surveyId: number, deviceId: string): Promise<boolean>;
  PatientRelease(surveyId: number): Promise<boolean>;
  UpdatePatient(patientDetails: Patient_Details): Promise<boolean>;
}

export function getApiInstance(url = null as string | null) {
  return new Legacy(url) as Api;
}
