import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApi from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";
import SurveyUser from "../../models/SurveyUser";
import { Container, Row, Col, Form, Button, InputGroup, ListGroup, ListGroupItem, Card } from "react-bootstrap";

interface AddPartyMemberFormElements extends HTMLFormControlsCollection {
    username: HTMLInputElement;
}

interface AddPartyMemberFormElement extends HTMLFormElement {
    readonly elements: AddPartyMemberFormElements;
}

export default function AddPartyMembers() {
    const api = useApi();
    let { user, partyMembers, updatePartyMembers } = useAuth();
    let [pendingPartyMembers, setPendingPartyMembers] = useState([] as Array<SurveyUser>);

    useEffect(() => {
        setPendingPartyMembers(partyMembers);
    }, [partyMembers]);

    const handleSubmit = async (e: React.FormEvent<AddPartyMemberFormElement>) => {
        e.preventDefault();

        const { currentTarget } = e;
        const { username } = currentTarget.elements;

        await addPartyMember(username.value);
        currentTarget.reset();
        username.focus();
    };


    async function addPartyMember(username: string): Promise<boolean> {
        if (username === user?.username) {
            return false;
        }
        if (pendingPartyMembers.find(pm => pm.username === username)) {
            return false;
        }
        let partyMember = await api.GetUser(username);
        if (!partyMember) {
            return false;
        }
        let temp = partyMember;
        setPendingPartyMembers(pm => [...pm, temp]);
        return true;
    }

    function removePartyMember(username: string) {
        setPendingPartyMembers(pm => pm.filter(m => m.username !== username));
    }

    return (
        <Container>
            <Row className="subheader py-3">
                <h1>Manage Party</h1>
            </Row>
            <Row className="justify-content-md-center">
                <Col md={8} lg={6} xl={5} className="align-self-center">
                    <Card className="add-party">
                        <Card.Body>
                            <Card.Title>
                                <h3 className="mb-4">Add New Party Member</h3>
                            </Card.Title>
                            <Row>
                                <Col>
                                    <Form onSubmit={handleSubmit}>
                                        <InputGroup className="mb-4">
                                            <Form.Control id="username" placeholder="New Party Member's HawkId" />
                                            <Button type="submit" variant="secondary" id="button-addon2">
                                                Add
                                            </Button>
                                        </InputGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ListGroup className="mb-4" variant="flush">
                                        {pendingPartyMembers.map(pm =>
                                            <ListGroupItem key={pm.username} className="d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto my-auto">
                                                    {pm.displayName}
                                                </div>
                                                <Button size="sm" variant="outline-danger" onClick={() => removePartyMember(pm.username)}>
                                                    Remove
                                                </Button>
                                            </ListGroupItem>
                                        )}
                                    </ListGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Link to="/units" className="d-grid">
                                    <Button variant="primary" onClick={() => updatePartyMembers(pendingPartyMembers)}>Save Party</Button>
                                </Link>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
