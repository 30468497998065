import useObfuscation from "../../hooks/useObfuscation";
import Button from "react-bootstrap/Button";


export default function ToggleObfuscation() {
    const {obfuscated, toggleObfuscation} = useObfuscation();

    return (
        <Button
            onClick={toggleObfuscation}
            variant="primary"
            size="sm"
        >
            { obfuscated ? "Show PHI" : "Hide PHI" }
        </Button>
    );
}
