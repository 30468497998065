import request from 'superagent';
import { Api } from ".";
import YearQuarter from '../../models/YearQuarter';
import AuthUser from '../../models/AuthUser';
import Patient_Details from '../../models/Patient_Details';
import SurveyUser from '../../models/SurveyUser';
import UnitData from '../../models/UnitData';
import Unit_Patient_Info from '../../models/Unit_Patient_Info'; 

const DEFAULT_URL = process.env.REACT_APP_API_URL || '';

export default class LegacyApi implements Api {
  private baseUrl: string;

  constructor(url: string | null) {
    this.baseUrl = (url || DEFAULT_URL).replace(/\/$/, ''); // Trim trailing slash
  }

  public async Authenticate(username: string, password: string) {
    let resVerified = await request
      .post(`${this.baseUrl}/api/user_verify`)
      .send({ username, password });

    if (!resVerified.ok)
      return null;

    return resVerified.body as AuthUser;
  }

  public async CheckConnection() {
    try {
      let res = await request
        .get(`${this.baseUrl}/api/CheckConnection`);

      return res.ok && res.body === 1;
    } catch (e) {
      return false;
    }
  }

  public async DeleteDeviceUser(username: string, deviceId: string) {
    let res = await request
      .get(`${this.baseUrl}/api/Device_User/delete/${deviceId}@${username}`);

    return res.body as boolean;
  }

  public async GetUser(username: string): Promise<SurveyUser | null> {
    let res = await request
      .get(`${this.baseUrl}/api/user_get/${username}`);

    if (res.body === "") {
      return null;
    }

    return {
      username: username,
      displayName: res.body
    };
  }

  public async AddDeviceUser(username: string, deviceId: string): Promise<boolean> {
    let res = await request
      .get(`${this.baseUrl}/api/Device_User/${deviceId}@${username}`);

    return res.ok;
  }

  public async GetUnits(yearQuarter: YearQuarter) {
    let res = await request
      .get(`${this.baseUrl}/api/Survey_Summary/${yearQuarter.valueOf()}`);

    return (res.body?.Summary_data || []) as UnitData[];
  }

  public async PatientDetail(unitDataId: number, date: Date = new Date()) {
    let res = await request
      .get(`${this.baseUrl}/api/Patient_Detail/${unitDataId}`);

    let unit = res.body as Unit_Patient_Info;

    if (unit) {
      let readonly = unit.YearQuarter < GetYearQuarter(date);
      unit.Patient_Info?.forEach(patient => {
        patient.Readonly = readonly;
      });
    }

    return unit;
  }

  public async PatientBind(surveyId: number, deviceId: string) {
    let res = await request
      .get(`${this.baseUrl}/api/Patient/bind/${deviceId}@${surveyId}`);

    return res.ok;
  }

  public async PatientRelease(surveyId: number) {
    let res = await request
      .get(`${this.baseUrl}/api/Patient/release/all@${surveyId}`);

    return res.ok;
  }

  public async UpdatePatient(patientDetails: Patient_Details) {
    let res = await request
      .post(`${this.baseUrl}/api/Patient/update`)
      .send(patientDetails);

    return res.body as boolean;
  }
}

export function GetYearQuarter(date: Date) {
  return YearQuarter.fromDate(date).valueOf();
}
