import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import AuthUser from "../models/AuthUser";
import SurveyUser from "../models/SurveyUser";
import Login from "../components/auth/Login";
import useApi from "./useApi";

export interface LoginService {
  login(username: string, password: string): Promise<boolean>;
}

export interface AuthenticatedContextState {
  user: AuthUser;
  partyMembers: Array<SurveyUser>;
  updatePartyMembers(partyMembers: Array<SurveyUser>): void;
  logout(): void;
}

export const AuthContext = createContext(null as AuthenticatedContextState | null);

export default function useAuth() {
  const auth = useContext(AuthContext);
  if (!auth) throw new Error("AuthProvider not found!");
  return auth;
}

const sessionKey = 'auth';
const partySessionKey = 'party';

function validateSurveyUser(survey: SurveyUser) {
  return survey && survey.username && survey.displayName;
}

function validateAuthUser(auth: AuthUser) {
  return auth && auth.deviceId && auth.username && auth.displayName;
}

export function AuthProvider(props: { children: any }) {
  const api = useApi();
  const [user, setUser] = useState(null as AuthUser | null);
  const [partyMembers, setPartyMembers] = useState([] as Array<SurveyUser>);

  useEffect(() => {
    try {
      let auth = JSON.parse(window.sessionStorage[sessionKey] || 'null') as AuthUser;
      let party = JSON.parse(window.sessionStorage[partySessionKey] || '[]') as Array<SurveyUser>;
      if (validateAuthUser(auth))
        setUser(auth);
      if (!party.find(su => !validateSurveyUser(su))) {
        setPartyMembers(party);
      }
    } catch (e) {
      console.warn(e);
    }
  }, []);

  useEffect(() => {
    window.sessionStorage[sessionKey] = user && JSON.stringify(user);
  }, [user]);

  useEffect(() => {
    window.sessionStorage[partySessionKey] = JSON.stringify(partyMembers);
  }, [partyMembers])

  async function login(username: string, password: string) {
    const user = await api.Authenticate(username, password);
    setUser(user);
    return !!user;
  }

  const updatePartyMembers = useCallback(
    async (updatedPartyMembers: Array<SurveyUser>) => {
      let partyMembersResult = [...partyMembers];
      for (let partyMember of partyMembers) {
        if (!updatedPartyMembers.includes(partyMember)
          && await api.DeleteDeviceUser(partyMember.username, user!.deviceId)) {
          partyMembersResult = partyMembersResult.filter(pm => pm.username !== partyMember.username);
        }
      }
      for (let partyMember of updatedPartyMembers) {
        if (!partyMembers.includes(partyMember)
          && await api.AddDeviceUser(partyMember.username, user!.deviceId)) {
          partyMembersResult = [...partyMembersResult, partyMember];
        }
      }
      setPartyMembers(partyMembersResult);
    }, [api, user, partyMembers]);

  function logout() {
    setUser(null);
    setPartyMembers([]);
  }

  const value = useMemo(() => ({
    user,
    partyMembers,
    updatePartyMembers,
    logout,
  }), [user, partyMembers, updatePartyMembers]) as AuthenticatedContextState;

  if (!user) {
    return (<Login login={login} />);
  }

  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  );
}
