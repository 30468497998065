import { getUlcerMapLocations, Side } from "../../lib/staticData/ulcerMapLocation";
import PatientBack from "./PatientBack";
import PatientFront from "./PatientFront";
import "./SvgStyles.css";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import Patient_Details from "../../models/Patient_Details";
import { ulcerLocations } from "../../lib/staticData/ulcerLocation";

export default function LocationMap(props: { unitId: number, patient: Patient_Details }) {
    const [side, setSide] = useState<Side>("front");
    const { locationId } = useParams<{ locationId: string }>();

    useEffect(() => {
        if (!locationId) {
            return;
        }
        if (getUlcerMapLocations("front").map(u => u.id).includes(locationId)) {
            setSide("front");
        } else {
            setSide("back");
        }
    }, [locationId]);

    return (
        <Row>
            <Col md={12} lg={2}>
                <Button
                    className="mb-4"
                    onClick={() => setSide(s => s === "front" ? "back" : "front")}
                >
                    Show {side === "front" ? "Back" : "Front"} Locations
                </Button>
            </Col>
            <Col md={12} lg={10}>
                <svg className="location-map mb-4" version="1.1" viewBox="0 0 126.75 291.64" xmlns="http://www.w3.org/2000/svg">
                    {side === "front" ? <PatientFront /> : <PatientBack />}
                    <g fill="rgba(0,0,0,0)">
                        {getUlcerMapLocations(side)!.map(l => {
                            const numUlcersAtLocation = (props.patient.Ulcer_Info ?? []).filter(u => u.Location === l.id).length;
                            return (
                                <Link title={ulcerLocations.get(l.id)} className="selector-link" key={l.id} to={`/units/${props.unitId}/patient/${props.patient.Survey_ID}/ulcers/location/${l.id}`}>
                                    {numUlcersAtLocation > 0 && <text fontSize="5" textAnchor="middle" stroke="none" fill="#000" x={l.cx} y={l.cy + 1.5}>{numUlcersAtLocation}</text>}
                                    <circle className={numUlcersAtLocation > 0 ? "location-circle-with-ulcers" : "location-circle"} cx={l.cx} cy={l.cy} r={3.5} />
                                </Link>
                            );
                        })}
                    </g>
                </svg>
            </Col>
        </Row>
    );

}
