import { createContext, useCallback, useContext, useEffect, useState } from "react";
import ApiNotConnectedPage from "../components/api/ApiNotConnectedPage";
import { getApiInstance } from "../lib/api";

enum ConnectionState {
    Checking,
    Good,
    Bad,
}

const apiUrlStorageKey = "apiurl";
const initialUrl = window.localStorage.getItem(apiUrlStorageKey);
const initialApi = getApiInstance(initialUrl);

export const ApiContext = createContext(initialApi);

export default function useApi() {
    return useContext(ApiContext);
}

export function ApiProvider(props: { children: any }) {
    const [api, setApi] = useState(initialApi);
    const [connectionState, setConnectionState] = useState(ConnectionState.Checking);

    const checkConnectionState = useCallback(async () => {
        setConnectionState(ConnectionState.Checking);
        let connectionTestResult = await api.CheckConnection();
        setConnectionState(connectionTestResult ? ConnectionState.Good : ConnectionState.Bad);
    }, [api]);

    useEffect(() => {
        checkConnectionState();
    }, [api, checkConnectionState]);

    function setBackendUrl(url: string) {
        setApi(getApiInstance(url));
        window.localStorage.setItem(apiUrlStorageKey, url);
    }

    switch (connectionState) {
        case ConnectionState.Good:
            return (
                <ApiContext.Provider value={api}>
                    {props.children}
                </ApiContext.Provider>
            );
        case ConnectionState.Checking:
            return (
                <div>Checking connection to the API...</div>
            );
        case ConnectionState.Bad:
            return <ApiNotConnectedPage setBackendUrl={setBackendUrl} />
    }
}
