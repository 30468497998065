export default function PatientFront() {
    return (
        <>
            <g>
                <text x="20.346" y="30.181" fill="#000" fontFamily="sans-serif" fontSize="5.6444px" textAnchor="middle">Mouth</text>
                <path d="m61.373 25.514a0.50005 0.50005 0 0 0-0.28516 0.88867l1.9746 1.5801a0.50005 0.50005 0 0 0 0.625 0l1.9727-1.5801a0.50005 0.50005 0 0 0-0.46094-0.86719l-1.8242 0.56836-1.8262-0.56836a0.50005 0.50005 0 0 0-0.17578-0.02148z" />
                <path d="m60.33 30.348a0.50005 0.50005 0 0 0-0.07803 0.99426l3.0443 0.48007a0.50005 0.50005 0 0 0 0.15606 0l3.0443-0.48007a0.50005 0.50005 0 0 0-0.07803-0.99426h-3.0443z" />
                <ellipse cx="78.019" cy="68.365" rx="1.7859" ry="1.4387" />
                <ellipse cx="48.73" cy="68.365" rx="1.7859" ry="1.4387" />
                <path d="m63.422 119.2c-1.9844 0-2.3482 5.7382 0 5.7382 2.3482 0 1.9844-5.7382 0-5.7382zm-0.04754 0.73949c0.60804 0 1.3622 3.6716 0 3.6716-1.3622 0-0.60804-3.6716 0-3.6716z" />
            </g>
            <g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
                <path d="m63.375 0.50001c-7.4837 0-13.831 2.4721-13.831 11.893v10.089c0 10.49 9.7555 15.502 13.831 15.502 4.0759 0 13.831-5.0116 13.831-15.502v-10.089c0-9.4213-6.3478-11.893-13.831-11.893z" />
                <path d="m61.838 18.541-0.41761 4.5269" />
                <path d="m49.524 17.137c-3.3073 0-3.5199 2.0789-3.5199 3.7089 0 1.63 0.16536 6.5201 4.1341 6.5201" />
                <path d="m77.224 17.137c3.3073 0 3.5199 2.0789 3.5199 3.7089 0 1.63-0.16537 6.5201-4.1341 6.5201" />
                <path d="m53.741 16.712s1.6954-0.33073 3.1601-0.33073c1.4647 0 2.2497 0.34254 2.2497 0.34254z" />
                <path d="m73.008 16.712s-1.6954-0.33073-3.1601-0.33073-2.2497 0.34254-2.2497 0.34254z" />
                <path d="m54.967 19.814s1.6702-0.39688 2.5797-0.39688c0.90951 0 1.3725 0.3638 1.3725 0.3638s-0.53102 0.33835-1.4479 0.33835c-0.91685 0-2.5043-0.30527-2.5043-0.30527z" />
                <path d="m71.781 19.814s-1.6702-0.39688-2.5797-0.39688c-0.9095 0-1.3725 0.3638-1.3725 0.3638s0.53102 0.33835 1.4479 0.33835c0.91685 0 2.5044-0.30527 2.5044-0.30527z" />
                <path d="m72.482 33.635c0 5.5191 3.5547 8.2787 8.1851 9.9625 4.6304 1.6838 4.5561 1.5841 9.6351 3.4611 5.3587 1.9804 9.7301 7.7505 11.167 15.86 1.74 9.8195 3.9291 29.419 3.7531 30.257-0.17631 0.8393 4.4809 11.751 5.1423 14.728 0.66146 2.9766 2.5797 25.202 3.3073 26.26 0.7276 1.0583 3.9026 2.6458 4.9609 3.7042 1.0583 1.0583 6.4547 7.6296 7.3422 9.9219 1.0156 2.6231-0.94071 3.7857-2.6532 2.2957-1.6618-1.4459-2.9078-4.0042-4.2307-4.0703-1.3229-0.0661-3.4015 0.33005-4.3115-0.62672-1.1604-1.2201-2.1661-2.4273-2.1661-2.4273" />
                <path d="m119.09 146.01c2.174 4.9246 6.1028 13.25 4.419 13.905-1.6838 0.65482-6.5013-8.5125-7.0158-9.8689 0 0-2.7128 2.3386-9.5883 1.5435" />
                <path d="m116.49 150.05c2.0112 6.1739 3.368 11.18 1.7306 11.74-1.554 0.53148-4.303-6.8755-4.9111-10.196 0.98222 5.1449 1.5435 10.103 0.32741 10.29s-3.4144-5.6127-3.8821-9.9625c0.70158 5.0514 1.0758 8.7932-0.37418 8.7464-1.4499-0.0468-2.3386-5.9868-2.4789-9.0738s-2.7137-7.4895-2.8999-8.7464c-0.18708-1.2628 1.3087-4.7895 0.37418-7.0158-1.5902-3.7886-6.7062-15.558-7.7174-19.364-1.2108-4.5569-1.84-12.231-2.3854-13.891-0.50343-1.5322-5.9401-16.815-6.0336-17.937" />
                <path d="m97.069 98.808c1.1781-0.94486 3.4415-1.5681 5.4901-1.356" />
                <path d="m54.267 33.635c0 5.5191-3.5547 8.2787-8.1851 9.9625-4.6304 1.6838-4.5561 1.5841-9.6351 3.4611-5.3587 1.9804-9.7301 7.7505-11.167 15.86-1.74 9.8195-3.9291 29.419-3.7531 30.257 0.17631 0.8393-4.4809 11.751-5.1423 14.728-0.66146 2.9766-2.5797 25.202-3.3073 26.26-0.7276 1.0583-3.9026 2.6458-4.9609 3.7042-1.0583 1.0583-6.4547 7.6296-7.3422 9.9219-1.0156 2.6231 0.94071 3.7857 2.6532 2.2957 1.6618-1.4459 2.9078-4.0042 4.2307-4.0703 1.3229-0.0661 3.4015 0.33005 4.3115-0.62672 1.1604-1.2201 2.1661-2.4273 2.1661-2.4273" />
                <path d="m7.6578 146.01c-2.174 4.9246-6.1028 13.25-4.419 13.905 1.6838 0.65482 6.5013-8.5125 7.0158-9.8689 0 0 2.7128 2.3386 9.5883 1.5435" />
                <path d="m29.68 98.808c-1.1781-0.94486-3.4415-1.5681-5.4901-1.356" />
                <path d="m68.74 50.005c1.5435-1.6838 5.1449-2.1983 7.5771-2.2918 2.4322-0.09354 2.8999-0.46772 2.8999-0.46772" />
                <path d="m58.009 50.005c-1.5435-1.6838-5.1449-2.1983-7.5771-2.2918-2.4322-0.09354-2.8999-0.46772-2.8999-0.46772" />

                <path d="m91.798 66.235c-2.6192 12.161-5.2854 26.65-5.0982 33.858 0.19263 7.4163 4.7021 11.815 3.9369 21.662-0.47867 6.1594 2.0627 14.047 2.4241 21.093 0.44586 8.6913-1.7773 23.667-3.274 33.489-1.4967 9.8222-5.7998 26.754-5.8465 30.589-0.0468 3.835 0.21333 6.2451 0.61607 13.945 0.36411 6.9612-7.0872 32.825-7.4061 36.095-0.33589 3.4448 1.8321 10.951 3.6095 15.535 1.7773 4.5837 3.2183 7.9265 3.5427 9.251 0.19843 0.81029 0.1819 2.7947-0.54571 3.175-0.7276 0.38034-1.5379 0.1819-1.7694-1.8356 0.26459 2.1167 0.64334 3.3268-0.19843 3.7042-0.47956 0.21497-1.9182 0.13229-2.1497-2.9766 0.31419 1.8852 0.41341 3.9522-0.678 4.1672-1.2284 0.24196-1.9844-0.87644-2.0836-3.7372 0.19844 2.1663 0.94258 4.5641-0.74414 4.6798-1.8184 0.12479-2.0836-1.4221-2.1663-4.2499 0.0662 2.1828 0.62839 4.6302-0.99219 4.746-1.6206 0.11575-3.0374-1.3749-2.927-3.4065 0.16535-3.0427-1.3587-4.1424-1.1245-7.6932 0.70849-10.739-3.4602-12.935-2.566-18.263 0.49264-2.9351 1.7737-4.3525 1.2592-10.667-0.38484-4.7231-2.4148-26.974-1.1225-33.629 0.67463-3.4742 1.5903-15.856 1.0758-18.802-0.5145-2.9466-2.9934-29.56-2.5725-39.897" />
                <path d="m79.111 214.63c2.249-1.9844 2.7664-4.5555 2.4474-7.8383-0.22822-2.3485 0.39687-4.2002 0.39687-4.2002" />
                <path d="m72.198 214.66c-1.356-2.249-2.0505-3.4065-1.4221-10.484" />
                <path d="m34.95 66.235c2.6192 12.161 5.2854 26.65 5.0982 33.858-0.19263 7.4163-4.7021 11.815-3.9369 21.662 0.47867 6.1594-2.0627 14.047-2.4241 21.093-0.44586 8.6913 1.7773 23.667 3.274 33.489 1.4967 9.8222 5.7998 26.754 5.8465 30.589 0.0468 3.835-0.21333 6.2451-0.61607 13.945-0.36411 6.9612 7.0872 32.825 7.4061 36.095 0.33589 3.4448-1.8321 10.951-3.6095 15.535-1.7773 4.5837-3.2183 7.9265-3.5427 9.251-0.19843 0.81029-0.1819 2.7947 0.54571 3.175 0.7276 0.38034 1.5379 0.1819 1.7694-1.8356-0.26459 2.1167-0.64334 3.3268 0.19843 3.7042 0.47956 0.21497 1.9182 0.13229 2.1497-2.9766-0.31419 1.8852-0.41341 3.9522 0.678 4.1672 1.2284 0.24196 1.9844-0.87644 2.0836-3.7372-0.19844 2.1663-0.94258 4.5641 0.74414 4.6798 1.8184 0.12479 2.0836-1.4221 2.1663-4.2499-0.0662 2.1828-0.62839 4.6302 0.99219 4.746 1.6206 0.11575 3.0374-1.3749 2.927-3.4065-0.16535-3.0427 1.3587-4.1424 1.1245-7.6932-0.70849-10.739 3.4602-12.935 2.566-18.263-0.49264-2.9351-1.7737-4.3525-1.2592-10.667 0.38485-4.7231 2.4148-26.974 1.1225-33.629-0.67463-3.4742-1.5903-15.856-1.0758-18.802 0.5145-2.9466 2.9934-29.56 2.5725-39.897" />
                <path d="m47.638 214.63c-2.249-1.9844-2.7664-4.5555-2.4474-7.8383 0.22822-2.3485-0.39687-4.2002-0.39687-4.2002" />
                <path d="m54.55 214.66c1.356-2.249 2.0505-3.4065 1.4221-10.484" />
                <path d="m77.622 145.04c-6.35 6.9784-10.537 12.204-14.248 12.204-3.7107 0-7.8979-5.2255-14.248-12.204" />
                <path d="m70.049 85.348c3.4727 3.0427 11.377 10.352 13.295 13.461" strokeDasharray="3, 3" />
                <path d="m56.7 85.348c-3.4727 3.0427-11.377 10.352-13.295 13.461" strokeDasharray="3, 3" />
                <path d="m20.274 30.711c7.1186 5.1258 28.431 18.606 37.703 0.59531" strokeWidth=".5" />
                <path d="m10.255 150.05c-2.0112 6.1739-3.368 11.18-1.7306 11.74 1.554 0.53148 4.303-6.8755 4.9111-10.196-0.98222 5.1449-1.5435 10.103-0.32741 10.29s3.4144-5.6127 3.8821-9.9625c-0.70158 5.0514-1.0758 8.7932 0.37418 8.7464 1.4499-0.0468 2.3386-5.9868 2.4789-9.0738s2.7137-7.4895 2.8999-8.7464c0.18708-1.2628-1.3087-4.7895-0.37418-7.0158 1.5902-3.7886 6.7062-15.558 7.7174-19.364 1.2108-4.5569 1.84-12.231 2.3854-13.891 0.50343-1.5322 5.9401-16.815 6.0336-17.937" />
            </g>
        </>
    );
}
