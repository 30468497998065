export type Side = "front" | "back";

const frontLocations = [
    { "id": "2", "cx": 72.794, "cy": 252.82 },
    { "id": "4", "cx": 53.955, "cy": 252.82 },
    { "id": "5", "cx": 63.374, "cy": 70.453 },
    { "id": "6", "cx": 101.37, "cy": 103.06 },
    { "id": "7", "cx": 25.38, "cy": 103.06 },
    { "id": "10", "cx": 70.875, "cy": 26.929 },
    { "id": "11", "cx": 55.873, "cy": 26.929 },
    { "id": "12", "cx": 63.374, "cy": 38.306 },
    { "id": "14", "cx": 86.155, "cy": 22.299 },
    { "id": "16", "cx": 40.594, "cy": 22.299 },
    { "id": "20", "cx": 74.249, "cy": 271.67 },
    { "id": "22", "cx": 52.5, "cy": 271.67 },
    { "id": "24", "cx": 113.08, "cy": 145.4 },
    { "id": "26", "cx": 13.672, "cy": 145.4 },
    { "id": "61", "cx": 85.824, "cy": 132.83 },
    { "id": "62", "cx": 40.924, "cy": 132.83 },
    { "id": "32", "cx": 75.836, "cy": 208.57 },
    { "id": "34", "cx": 50.912, "cy": 208.57 },
    { "id": "36", "cx": 105.8, "cy": 123.63 },
    { "id": "38", "cx": 20.948, "cy": 123.63 },
    { "id": "39", "cx": 75.77, "cy": 230.79 },
    { "id": "40", "cx": 50.979, "cy": 230.79 },
    { "id": "44", "cx": 63.374, "cy": 49.882 },
    { "id": "45", "cx": 63.374, "cy": 18.992 },
    { "id": "52", "cx": 86.486, "cy": 57.819 },
    { "id": "53", "cx": 40.263, "cy": 57.819 },
    { "id": "56", "cx": 77.49, "cy": 177.74 },
    { "id": "58", "cx": 49.259, "cy": 177.74 },
    { "id": "59", "cx": 78.201, "cy": 287.7 },
    { "id": "60", "cx": 48.548, "cy": 287.7 },
    { "id": "63", "cx": 96.738, "cy": 82.425 },
    { "id": "66", "cx": 30.01, "cy": 82.425 },
    { "id": "70", "cx": 63.374, "cy": 7.3499 },
    { "id": "71", "cx": 63.374, "cy": 130.32 },
    { "id": "73", "cx": 63.374, "cy": 151.35 },
    { "id": "74", "cx": 20.091, "cy": 21.436 }
];
const backLocations = [
    { "id": "1", "cx": 54.22, "cy": 252.68 },
    { "id": "3", "cx": 72.529, "cy": 252.68 },
    { "id": "8", "cx": 48.134, "cy": 149.03 },
    { "id": "9", "cx": 78.614, "cy": 149.03 },
    { "id": "13", "cx": 41.123, "cy": 21.77 },
    { "id": "15", "cx": 85.626, "cy": 21.77 },
    { "id": "17", "cx": 24.256, "cy": 108.62 },
    { "id": "18", "cx": 102.49, "cy": 108.62 },
    { "id": "19", "cx": 51.061, "cy": 277.51 },
    { "id": "21", "cx": 75.687, "cy": 277.51 },
    { "id": "23", "cx": 12.283, "cy": 149.23 },
    { "id": "25", "cx": 114.47, "cy": 149.23 },
    { "id": "27", "cx": 54.154, "cy": 265.65 },
    { "id": "28", "cx": 72.595, "cy": 265.65 },
    { "id": "31", "cx": 51.442, "cy": 212.2 },
    { "id": "33", "cx": 75.307, "cy": 212.2 },
    { "id": "35", "cx": 20.287, "cy": 127.54 },
    { "id": "37", "cx": 106.46, "cy": 127.54 },
    { "id": "41", "cx": 74.315, "cy": 234.03 },
    { "id": "42", "cx": 52.434, "cy": 234.03 },
    { "id": "43", "cx": 63.374, "cy": 45.648 },
    { "id": "46", "cx": 63.374, "cy": 20.381 },
    { "id": "47", "cx": 63.374, "cy": 76.208 },
    { "id": "48", "cx": 63.374, "cy": 144.21 },
    { "id": "49", "cx": 63.374, "cy": 4.9686 },
    { "id": "50", "cx": 50.714, "cy": 73.165 },
    { "id": "51", "cx": 76.035, "cy": 73.165 },
    { "id": "54", "cx": 63.374, "cy": 99.094 },
    { "id": "55", "cx": 50.449, "cy": 185.68 },
    { "id": "57", "cx": 76.299, "cy": 185.68 },
    { "id": "29", "cx": 44.496, "cy": 161.14 },
    { "id": "30", "cx": 82.252, "cy": 161.14 },
    { "id": "64", "cx": 30.672, "cy": 87.452 },
    { "id": "65", "cx": 96.077, "cy": 87.452 },
    { "id": "72", "cx": 63.374, "cy": 130.32 }
];

export function getUlcerMapLocations(side: Side) {
    switch (side) {
        case "front":
            return frontLocations;
        case "back":
            return backLocations;
        default:
            throw new Error(`Unexpected side '${side}'.`);
    }
}
