import { createContext, useContext, useMemo, useState } from "react";

export interface ObfuscationContextState {
    obfuscated: boolean,
    toggleObfuscation(): void
}

export const ObfuscationContext = createContext(null as ObfuscationContextState | null);

export default function useObfuscation() {
  const obfuscation = useContext(ObfuscationContext);
  if (!obfuscation) throw new Error("Obfuscation Provider not found!");
  return obfuscation;
}

export function ObfuscationProvider(props: {children: any}) {
    const [obfuscated, setObfuscated] = useState(false);
    
    function toggleObfuscation() {
        setObfuscated(o => !o);
    }

    let value = useMemo(() => ({
        obfuscated,
        toggleObfuscation
    }), [obfuscated])

    return (
        <ObfuscationContext.Provider value={value}>
            { props.children }
        </ObfuscationContext.Provider>
    );
}
