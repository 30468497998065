import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApi from "../../hooks/useApi";
import UnitData from "../../models/UnitData";
import { Spinner, Container, Row, Col, Card, ListGroup, ListGroupItem, Badge } from "react-bootstrap";
import YearQuarterPicker from "../yearquarter/YearQuarterPicker";
import useYearQuarter from "../../hooks/useYearQuarter";

export default function UnitList(props: { lastUpdated: Date }) {
  return (
    <Container>
      <Row className="my-4">
        <Col sm={6} lg={8}>
          <h1>Select a Unit</h1>
        </Col>
        <Col sm={6} lg={4}>
          <YearQuarterPicker />
        </Col>
      </Row>
      <Row>
        <Col>
          <Units {...props} />
        </Col>
      </Row>
    </Container>
  )
}

function Units({ lastUpdated }: { lastUpdated: Date }) {
  const api = useApi();
  const [units, setUnits] = useState(null as UnitData[] | null);
  const { yearQuarter } = useYearQuarter();

  useEffect(() => {
    let fetchUnits = async () => {
      setUnits(await api.GetUnits(yearQuarter));
    };

    fetchUnits();
  }, [api, yearQuarter, lastUpdated]);

  if (!units)
    return (
      <div className="loading-screen">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );


  return (
    <Container>
      <Row>
        {units.map(unit => {
          const totalParticipating = unit.Total_Census - unit.Refused - unit.Absent - unit.Unsafe - unit.Dying - unit.Discharged;
          return (
            <Col md={6} lg={4} key={unit.Unit_Name} className="mb-4">
              <Card className="h-100" as={Link} to={`/units/${unit.Unit_Data_ID}`}>
                <Card.Body>
                  <Card.Title>
                    {unit.Unit_Name}
                  </Card.Title>
                  <ListGroup variant="flush">
                    <ListGroupItem>
                      <Badge bg="secondary">{totalParticipating}</Badge>
                      <span className="mx-2">Patients</span>
                    </ListGroupItem>
                    <ListGroupItem>
                      <Badge bg="success">{totalParticipating - unit.Num_In_Process - unit.Num_Complete}</Badge>
                      <span className="mx-2">Ready/Not Started</span>
                    </ListGroupItem>
                    <ListGroupItem>
                      <Badge bg="danger">{unit.In_Process_Ulcers}</Badge>
                      <span className="mx-2">Not Done/With Wounds</span>
                    </ListGroupItem>
                    <ListGroupItem>
                      <Badge bg="warning">{unit.Injury}</Badge>
                      <span className="mx-2">Done/With Wounds</span>
                    </ListGroupItem>
                    <ListGroupItem>
                      <Badge bg="info">{unit.No_Injury}</Badge>
                      <span className="mx-2">Done/Without Wounds</span>
                    </ListGroupItem>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          )
        })}
      </Row>
    </Container>
  );
}
